import { ReactElement, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ParallaxBanner } from 'react-scroll-parallax';
import useMeasure from 'react-use-measure';
import { useRecoilState } from 'recoil';
import { MenuState } from 'states/atoms/MenuAtom';
import styled, { css } from 'styled-components';
import { IActiveable } from 'types';

type Props = {
  title?: string;
  subTitle?: string;
  bannerImg?: string;
  children?: ReactElement | ReactElement[];
};

const Layout = ({ title, subTitle, bannerImg, children }: Props) => {
  const pages = useRecoilState(MenuState)[0];
  const location = useLocation();
  const navigate = useNavigate();
  const [ref, bounds] = useMeasure();

  const currentPage = useMemo(() => {
    const filtered = pages?.data?.filter(menu => location.pathname.includes(menu.attributes.link.split('/')[1]));

    if (filtered) {
      return filtered[0];
    }

    return undefined;
  }, [pages, location]);

  const currentSubPage = useMemo(() => {
    if (currentPage) {
      const filtered = currentPage.attributes.menus?.data?.filter(sub => location.pathname.includes(sub.attributes.link));

      if (filtered) {
        return filtered[0];
      }
    }

    return undefined;
  }, [currentPage, location]);

  const subPages = useMemo(() => {
    if (currentPage && currentPage?.attributes.menus?.data) {
      const copied = [...currentPage.attributes.menus.data];

      return copied.sort((a, b) => a.attributes.order - b.attributes.order);
    }

    return [];
  }, [currentPage]);

  const moveTo = (url: string) => {
    navigate(url);
  };

  return (
    <Container>
      <Banner>
        <BannerTitle>{title}</BannerTitle>
        <BannerSubTitle>{subTitle}</BannerSubTitle>
        <ParallaxBanner layers={[{ image: bannerImg, speed: 10 }]} style={{ width: '100vw', height: 300, position: 'absolute', zIndex: 1 }} />
      </Banner>
      <SubMenu ref={ref}>
        <SubMenuTitleWrapper>
          <SubMenuTitle>{currentPage?.attributes.name}</SubMenuTitle>
        </SubMenuTitleWrapper>
        {subPages.map(sub => (
          <SubMenuWrapper key={sub.id} active={location.pathname.includes(sub.attributes.link)}>
            <SubMenuText dangerouslySetInnerHTML={{ __html: sub.attributes.name ?? '' }} onClick={() => moveTo(sub.attributes.link)}></SubMenuText>
          </SubMenuWrapper>
        ))}
      </SubMenu>
      <Body paddingLeft={bounds.x + bounds.width}>
        <SubTitle dangerouslySetInnerHTML={{ __html: currentSubPage?.attributes.name ?? '' }}></SubTitle>
        <Border />
        {children}
      </Body>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Banner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 300px;
  background-color: #333;
`;

const BannerTitle = styled.p`
  font-size: 42px;
  font-weight: bold;
  color: #fff;
  margin: 0px;
  padding: 5px;
  z-index: 2;
`;

const BannerSubTitle = styled.p`
  font-size: 32px;
  font-weight: bold;
  color: #f0bc5e;
  margin: 0px;
  padding: 5px;
  z-index: 2;
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 250px;
  left: 5px;
  background-color: #fff;
  box-shadow: 2px 2px 5px #333;
  z-index: 3;

  @media (max-width: 1024px) {
    display: none;
  }
  @media (min-width: 1248px) {
    left: 5%;
  }
  @media (max-width: 1248px) {
    left: 1%;
  }
`;

const SubMenuTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0bc5e;
`;

const SubMenuTitle = styled.p`
  color: #333;
  font-weight: bold;

  font-size: 21px;

  margin: 0px;
  padding: 16px 30px;
`;

const SubMenuWrapper = styled.div<IActiveable>`
  display: flex;
  padding: 0px 5px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in;
  ${({ active }) =>
    active &&
    css`
      background-color: #ffe8bc;
    `}
`;

const SubMenuText = styled.p`
  padding: 0px;
  font-weight: bold;
  cursor: pointer;

  text-align: center;
`;

type BodyProps = {
  paddingLeft?: number;
};

const Body = styled.div<BodyProps>`
  display: flex;
  flex-direction: column;
  ${({ paddingLeft }) => css`
    padding: 10px ${paddingLeft ?? 260}px 50px ${(paddingLeft ?? 260) + 20}px;
  `}
  @media (max-width: 1024px) {
    padding: 10px 10px;
  }
`;

const SubTitle = styled.p`
  font-size: 42px;
  font-weight: bold;
  color: #f0bc5e;
  margin: 10px;
  padding: 5px 0px;
`;

const Border = styled.div`
  width: 100%;
  height: 2px;
  background: #fc8d43;
  background: -webkit-linear-gradient(to right, #f0bc5e, #f9d423);
  background: linear-gradient(to right, #f0bc5e, #f9d423);
  margin-bottom: 20px;
`;

export default Layout;
