import { getImage } from '@lib';
import { Box } from '@mui/material';
import { getOrganization } from 'apis/ComponentsAPI';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { languageState } from 'states/atoms/LanguageAtom';

const Organization = () => {
  const lang = useRecoilState(languageState)[0];
  const { data: info } = useQuery(['organization', lang], () => getOrganization({ lang }));

  return (
    <>
      <Box sx={{ display: 'flex', minHeight: 400 }} padding={1} justifyContent="center" alignItems="center">
        <Box display="flex" padding={2} sx={{ width: '100%' }} justifyContent="center" alignItems="center">
          <img src={getImage(info?.data.attributes.image)} style={{ objectFit: 'contain', height: '100%', maxWidth: 1000 }} />
        </Box>
      </Box>
    </>
  );
};

export default Organization;
