// src/image/index.js

import img1 from './2-1.png';
import img2 from './3-2.png';
import img3 from './5-3.png';
import img4 from './4-1.png';
import img5 from './1111.png';
import img6 from './1-3-1.png';
import img7 from './1-3.png';
import untitled67 from './untitled.67.png';
import untitled66 from './untitled.66.png';
import video1 from './11.mp4'; // 비디오도 함께 import
import video2 from './22.mp4'; // 비디오도 함께 import
import logo from './logo.png';

const images = {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  untitled67,
  untitled66,
  video1,
  video2,
  logo,
};

export default images;
