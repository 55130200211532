import { useEffect } from 'react';
import './CES.css';
import images from './image';
import styled from 'styled-components';

const PageContainer = styled.div`
  background: linear-gradient(to bottom, rgb(1, 1, 1), rgb(255, 255, 255));
  font-family: sans-serif;
`;

function CES() {
  useEffect(() => {
    // 스크롤 핸들러 함수 정의
    const handleScroll = () => {
      const wrapper = document.getElementById('wrapper');
      const box1 = document.querySelector('.box1');
      const box2 = document.querySelector('.box2');
      const image1 = document.querySelector('.image1');
      const wrapper1 = document.getElementById('wrapper1');
      const img2 = document.querySelector('.img2');
      const img1 = document.querySelector('.img1');
      const boxes = document.querySelectorAll('.box4, .box5, .box6, .box7, .box8, .box9, .box10, .box11, .box12, .box13, .box14, .box16,  .box18 .box19, .box20, .box21, .box22, .box23, .box24');
      const wrapper2 = document.getElementById('wrapper2');
      const wrapper3 = document.getElementById('wrapper3');
      const wrapper4 = document.getElementById('wrapper4');
      const wrapper5 = document.getElementById('wrapper5');
      const wrapper6 = document.getElementById('wrapper6');
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      if (wrapper && box1 && box2 && image1 && wrapper1 && img2 && img1 && wrapper2 && wrapper3 && wrapper4 && wrapper5 && wrapper6) {
        const wrapperOffsetTop = wrapper.offsetTop;
        const wrapperHeight = wrapper.offsetHeight;
        const wrapper1OffsetTop = wrapper1.offsetTop;
        const wrapper1Height = wrapper1.offsetHeight;
        const wrapper2OffsetTop = wrapper2.offsetTop;
        const wrapper2Height = wrapper2.offsetHeight;
        const wrapper3OffsetTop = wrapper3.offsetTop;
        const wrapper3Height = wrapper3.offsetHeight;
        const wrapper4OffsetTop = wrapper4.offsetTop;
        const wrapper4Height = wrapper4.offsetHeight;
        const wrapper5OffsetTop = wrapper5.offsetTop;
        const wrapper5Height = wrapper5.offsetHeight;

        const threshold60 = wrapperOffsetTop + wrapperHeight * 0.2;
        const threshold100 = wrapperOffsetTop + wrapperHeight * 0.6;
        const threshold80 = wrapperOffsetTop + wrapperHeight * 0.7;
        const threshold801 = wrapper1OffsetTop + wrapper1Height * 0.6;
        const threshold802 = wrapper2OffsetTop + wrapper2Height * 0.6;
        const threshold803 = wrapper3OffsetTop + wrapper3Height * 0.41;
        const threshold804 = wrapper4OffsetTop + wrapper4Height * 0.41;
        const threshold805 = wrapper5OffsetTop + wrapper5Height * 0.5;
        const screenHeight = window.innerHeight;
        const middleScreen = screenHeight / 2;

        if (scrollTop > threshold100) {
          image1.classList.add('fade-out');
          img1.classList.add('fade-out');
          image1.classList.remove('fade-in');
          img1.classList.remove('fade-in');
        } else {
          image1.classList.remove('fade-out');
          img1.classList.remove('fade-out');
          image1.classList.add('fade-in');
          img1.classList.add('fade-in');
        }

        if (scrollTop > threshold60) {
          box1.classList.add('fade-out');
          box2.classList.add('fade-out');
          img2.classList.add('fade-out');
          box1.classList.remove('fade-in');
          box2.classList.remove('fade-in');
          img2.classList.remove('fade-in');
        } else {
          box1.classList.remove('fade-out');
          box2.classList.remove('fade-out');
          img2.classList.remove('fade-out');
          box1.classList.add('fade-in');
          box2.classList.add('fade-in');
          img2.classList.add('fade-in');
        }

        if (scrollTop > threshold80) {
          wrapper1.classList.remove('fade-out');
          wrapper1.classList.add('fade-in');
        } else {
          wrapper1.classList.add('fade-out');
          wrapper1.classList.remove('fade-in');
        }

        if (scrollTop > threshold801) {
          wrapper2.classList.remove('fade-out');
          wrapper2.classList.add('fade-in');
        } else {
          wrapper2.classList.add('fade-out');
          wrapper2.classList.remove('fade-in');
        }

        if (scrollTop > threshold802) {
          wrapper3.classList.remove('fade-out');
          wrapper3.classList.add('fade-in');
        } else {
          wrapper3.classList.add('fade-out');
          wrapper3.classList.remove('fade-in');
        }

        if (scrollTop > threshold803) {
          wrapper4.classList.remove('fade-out');
          wrapper4.classList.add('fade-in');
        } else {
          wrapper4.classList.add('fade-out');
          wrapper4.classList.remove('fade-in');
        }

        if (scrollTop > threshold804) {
          wrapper5.classList.remove('fade-out');
          wrapper5.classList.add('fade-in');
        } else {
          wrapper5.classList.add('fade-out');
          wrapper5.classList.remove('fade-in');
        }

        if (scrollTop > threshold805) {
          wrapper6.classList.remove('fade-out');
          wrapper6.classList.add('fade-in');
        } else {
          wrapper6.classList.add('fade-out');
          wrapper6.classList.remove('fade-in');
        }

        boxes.forEach(box => {
          if (box instanceof HTMLElement) {
            const boxOffsetTop = box.getBoundingClientRect().top + scrollTop;
            const boxHeight = box.offsetHeight;

            if (boxOffsetTop < scrollTop + middleScreen && boxOffsetTop + boxHeight > scrollTop + middleScreen) {
              box.classList.remove('fade-out1');
              box.classList.add('fade-in1');
            } else {
              box.classList.add('fade-out1');
              box.classList.remove('fade-in1');
            }
          }
        });
      }
    };

    // 스크롤 이벤트 리스너 추가
    window.addEventListener('scroll', handleScroll);

    // 컴포넌트가 언마운트될 때 스크롤 이벤트 리스너 제거
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <PageContainer>
      <body>
        {/* header */}
        {/* <div className="Rectangle-123">
          <span className="Korean"> Korean </span>
          <span className="SEP"> SEP </span>
        </div> */}

        <div className="image1">
          <div className="common-img img4"></div>
          <div className="common-img img3"></div>
        </div>
        <div className="common-img img2"></div>
        <div className="common-img img1"></div>

        {/* first-page wrapper */}
        <div id="wrapper">
          <div className="box1">
            <div className="Adaptive-Front-lighting">Adaptive Front-lighting</div>
            <div className="using">using</div>
            <div className="nano-scaled-meta-surface">nano scaled meta-surface</div>
          </div>
          <div className="box2">
            <div className="SEPs-Adaptive-Front-lighting-using-nano-scaled-meta-surface-is-more-compact-than-previous-models">
              SEP's ‘Adaptive Front-lighting using nano scaled meta-surface’ is more compact than previous models due to its use of RGB V CSEL beams and metasurface elements. This product provides
              safety and comfort to drivers through its high beam, low beam, drive assistance and steering assistance functions.
            </div>
            <div className="mode1">
              <div className="mo1">
                <span className="Low-Beam-module">
                  <span className="Ellipse-8"></span>
                  Low-Beam module
                </span>
                <span className="High-Beam-module">
                  <span className="Ellipse-9"></span>
                  High-Beam module
                </span>
              </div>
            </div>
            <div className="mode2">
              <div className="mo3">
                <span className="Driving-Assistance">
                  <span className="Ellipse-10"></span>
                  Driving-Assistance
                </span>
                <span className="Steering-Assistance">
                  <span className="Ellipse-11"></span>
                  Steering-Assistance
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* second-page wrapper1 */}
        <div id="wrapper1">
          <div className="common-box box3">
            <div className="mo">Modules</div>
          </div>
          <div className="common-box box4">
            <img src={images.img1} alt="" />
          </div>
          <div className="common-box box5">
            <img src={images.img2} alt="" />
          </div>
          <div className="common-box box6">
            <img src={images.img3} alt="" />
          </div>
          <div className="common-box box7">
            <img src={images.img4} alt="" />
          </div>
          <div className="common-box box8">
            <div className="common-title H">High-Beam-module</div>
            <div className="common-text H-t">Blocking glare from oncoming vehicles through individual LD ​​control.</div>
          </div>
          <div className="common-box box9">
            <div className="common-title L">Low-Beam-module</div>
            <div className="common-text L-t">Control lighting according to driver's environment with pattern shifting using motors.</div>
          </div>
          <div className="common-box box10">
            <div className="common-title S1">Steering-Assistance</div>
            <div className="common-text S1-t">Intuitive confirmation of driving and gear status through projection in the driver's line of sight</div>
          </div>
          <div className="common-box box11">
            <div className="common-title D">Driving-Assistance</div>
            <div className="common-text D-t">When changing lanes, check for obstacles in the lane to be changed and indicate the direction to move in front of the vehicle with an arrow..</div>
          </div>
        </div>

        {/* third-page wrapper2 */}
        <div id="wrapper2">
          <div className="detail">Learn more in detail</div>
          <div className="box12">
            <img src={images.untitled67} alt="" />
          </div>
          <div className="box14">
            <div className="common-title C">Compact</div>
            <div className="common-text C-t">
              Approximately 60% smaller in size compared to traditional headlamps It can be applied to various vehicle environments, potentially leading to innovative changes in vehicle exterior
              design in the future.
            </div>
            <div className="common-text C-t2">
              <p>Size(L X W X H) : 18.16 X 3.15 X 5.98(in)</p>
              Weight : 4lbs
            </div>
          </div>
        </div>

        {/* fourth-page wrapper3 */}
        <div id="wrapper3">
          <div className="box15">
            <div className="common-title S">Smart</div>
            <div className="common-text S-t">Adjusting light distribution to suit the driving environment through AI</div>
          </div>
          <div className="box16">
            <div className="S-v">
              <video src={images.video2} width="100%" height="100%" muted autoPlay playsInline loop></video>
            </div>
          </div>
        </div>

        {/* fifth-page wrapper4 */}
        <div id="wrapper4">
          <div className="box17">
            <div className="common-title CN">Carbon neutral</div>
            <div className="common-text CN-t">
              Replacing traditional components such as projection mirrors and lenses with metasurfaces, simplifying the production process Applicable to various vehicle environments
            </div>
          </div>
          <div className="box18">
            <div className="CN-i">
              <img src={images.untitled66} alt="" />
            </div>
          </div>
        </div>

        {/* sixth-page wrapper5 */}
        <div id="wrapper5">
          <div className="wrap5box tech">Technical aspects</div>
          <div className="wrap5box box19">
            <img src={images.img5} alt="" />
          </div>
          <div className="wrap5box box20">
            <img src={images.img6} alt="" />
          </div>
          <div className="wrap5box box21">
            <img src={images.img7} alt="" />
          </div>
          <div className="wrap5box box22">
            <div className="common-title ms">Meta-Surface Elements</div>
            <div className="common-text ms-t">
              This product utilizes Meta-suface technology to implement Adaptive Front-lighting Systems (AFS). Replacing several components of traditional headlights with Meta-surface element.
            </div>
          </div>
          <div className="wrap5box box23">
            <div className="common-title vc">Vcsel individual control</div>
            <div className="common-text vc-t">In case of High-beam, it is achieved by individual control of Vcsels.</div>
          </div>
          <div className="wrap5box box24">
            <div className="common-title lm">Linear manner</div>
            <div className="common-text lm-t">
              Low-beam, Driving-Assistance and Steering assistance are implemented in a linear manner, switching light distribution natully without any optical aberration.
            </div>
          </div>
        </div>

        {/* sevneth-page wrapper6 */}

        <div id="wrapper6">
          <div className="pv">Product video</div>
          <div className="pvv">
            <video src={images.video1} width="100%" height="100%" muted autoPlay playsInline loop></video>
          </div>
        </div>

        {/* footer(Root) */}

        {/* <div className="root">
          <div className="logo">
            <img src={images.logo} alt="" />
          </div>
          <div className="tt">
            <p>TEL: +82 31-8041-1784</p>
            <p>FAX: +82 31-8041-1786</p>
            <p>Head office: Room 435, Industry-Univercity Convergence Building, 237 Sangidaehak-ro, Siheung-si, Gyenonggi-do(TECH Univercity of Korea, Jeongwang-dong)</p>
            <p>Research center: Room 419, TIP Building, 237 Sangidaehak-ro, Siheung-si, Gyenonggi-do(TECH Univercity of Korea, Jeongwang-dong)</p>
          </div>
          <div className="tt2">Copyright ⓒ SEPCooperatives All rights reserved</div>
        </div> */}
      </body>
    </PageContainer>
  );
}

export default CES;
