import { Box, styled } from '@mui/material';
import MenuCard from 'components/MenuCard';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import BatchPredictionRoundedIcon from '@mui/icons-material/BatchPredictionRounded';
import { Parallax } from 'react-scroll-parallax';
import { useNavigate } from 'react-router-dom';

const MainCards = () => {
  const navigate = useNavigate();

  const onCardClick = (url: string) => {
    navigate(url);
  };

  return (
    <Container>
      <Parallax speed={5} scale={[1.1, 0.8]}>
        <MenuWrapper>
          <MenuCard title="회사소개" subTitle="Company Introduction" onClick={() => onCardClick('/company/1')} icon={<BusinessCenterRoundedIcon style={{ color: '#333', fontSize: '42px' }} />} />
          <MenuCard title="사업소개" subTitle="Business Introduction" onClick={() => onCardClick('/business/1')} icon={<AssignmentRoundedIcon style={{ color: '#333', fontSize: '42px' }} />} />
          <MenuCard title="사업분야" subTitle="Business Areas" onClick={() => onCardClick('/field/2')} icon={<BatchPredictionRoundedIcon style={{ color: '#333', fontSize: '42px' }} />} />
          <MenuCard title="문의" subTitle="Contact us" onClick={() => onCardClick('/community/1')} icon={<MailRoundedIcon style={{ color: '#333', fontSize: '42px' }} />} />
        </MenuWrapper>
      </Parallax>
    </Container>
  );
};

const Container = styled(Box)`
  width: 100vw;
  height: 400px;
  margin-top: -180px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

const MenuWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export default MainCards;
