import React from 'react';
import ReactDOM from 'react-dom/client';
// import 'resources/font/nanumgothic/font.css';
import './index.css';
import Router from './Router';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import { ParallaxProvider } from 'react-scroll-parallax';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={new QueryClient()}>
      <RecoilRoot>
        <ThemeProvider theme={theme}>
          <ParallaxProvider>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </ParallaxProvider>
        </ThemeProvider>
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
