import { Route, Routes } from 'react-router-dom';
import Layout from 'components/sub/Layout';
import BannerImage from 'resources/img/main_banner.png';
import Metaverse from 'components/sub/businessAreas/Metaverse';
import Industrial from 'components/sub/businessAreas/Industrial';
import NZaas from 'components/sub/businessAreas/NZaas';
import EnMS from 'components/sub/businessAreas/EnMS';
import NetZero from 'components/sub/businessAreas/NetZero';
import SmartLED from 'components/sub/businessAreas/SmartLED';

const subComponents = [
  {
    path: '2',
    element: <Metaverse />,
  },
  {
    path: '3',
    element: <Industrial />,
  },
  {
    path: '4',
    element: <NZaas />,
  },
  {
    path: '5',
    element: <EnMS />,
  },
  {
    path: '6',
    element: <NetZero />,
  },
  {
    path: '7',
    element: <SmartLED />,
  },
];

const Areas = () => {
  return (
    <Layout title="사업분야" subTitle="Business Areas" bannerImg={BannerImage}>
      <Routes>
        {subComponents.map(sub => (
          <Route key={sub.path} {...sub} />
        ))}
      </Routes>
    </Layout>
  );
};

export default Areas;
