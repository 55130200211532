import { Box } from '@mui/material';
import styled from 'styled-components';
import BANNER_IMG from 'resources/img/main_banner.png';
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';
import MainCards from 'components/main/MainCards';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { languageState } from 'states/atoms/LanguageAtom';
import { getMain } from 'apis/ComponentsAPI';
import { getImage } from '@lib';

const Main = () => {
  const lang = useRecoilState(languageState)[0];
  const { data: info } = useQuery(['main', lang], () => getMain({ lang }));
  return (
    <Container>
      <MainBanner>
        <Parallax speed={-6}>
          <MainBannerTitle>{info?.data.attributes.title}</MainBannerTitle>
        </Parallax>
        <Parallax speed={-7}>
          <MainBannerSubTitle>{info?.data.attributes.subTitle}</MainBannerSubTitle>
        </Parallax>
      </MainBanner>
      <ParallaxBanner layers={[{ image: BANNER_IMG, speed: 15 }]} style={{ width: '100vw', height: 700, position: 'absolute', zIndex: 1 }} />
      <MainCards />
      <Parallax translateX={[-230, 30]}>
        <div style={{ zIndex: 1, marginTop: -220, width: '200vw', height: 130, borderRadius: 70, backgroundColor: 'rgba(0,0,0, 0.2)' }}></div>
      </Parallax>
      <GreetingBox>
        <Parallax speed={3} scale={[1, 1.2]}>
          <GreetingTitle>GREETING</GreetingTitle>
        </Parallax>
        <Parallax speed={2} scale={[1, 1.1]}>
          <GreetingSubTitle dangerouslySetInnerHTML={{ __html: info?.data.attributes.greetingTitle ?? '' }} />
        </Parallax>

        <Parallax speed={1} scale={[0.9, 1]}>
          <GreetingBody dangerouslySetInnerHTML={{ __html: info?.data.attributes.greetingMessage ?? '' }} />
        </Parallax>

        <ParallaxBanner layers={[{ image: getImage(info?.data.attributes.greetingImage), speed: 5, opacity: [0.6, 1.5] }]} style={{ aspectRatio: '3/1', minHeight: 500, maxWidth: '80vw' }} />
      </GreetingBox>
    </Container>
  );
};

const MainBanner = styled.div`
  width: 100vw;
  height: 700px;
  /* background-image: url(${BANNER_IMG}); */
  object-fit: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;

  z-index: 2;
`;

const MainBannerTitle = styled.p`
  font-size: 4vw;
  font-weight: bold;
  color: #fff;

  margin: 0px;
  padding: 0px;

  @media (max-width: 1024px) {
    font-size: 42px;
  }
`;

const MainBannerSubTitle = styled.p`
  font-size: 46px;
  font-weight: bold;
  color: #f0bc5e;

  margin: 0px;
  padding: 0px;

  @media (max-width: 1024px) {
    font-size: 28px;
  }
`;

const GreetingBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 60px;
`;

const GreetingTitle = styled.p`
  font-size: 52px;
  font-weight: bold;
  color: #f0bc5e;

  margin: 0px;
  padding: 0px;
`;

const GreetingSubTitle = styled.p`
  font-size: 28px;
  font-weight: 700;
  color: #333;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
`;

const GreetingBody = styled.p`
  font-size: 18;
  font-weight: bold;
  color: #666;
  padding: 0px 10px;
  text-align: center;
  border-left: 3px solid #999;
  border-right: 3px solid #999;
  margin-left: 10;
  margin-right: 10;
  margin-bottom: 20;
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-bottom: 60px;
`;

export default Main;
