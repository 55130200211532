import { Route, Routes } from 'react-router-dom';
import Main from 'pages/Main';
import styled from 'styled-components';
import Nav from 'components/Nav';
import Footer from 'components/Footer';
import Company from 'pages/Company';
import Business from 'pages/Business';
import Community from 'pages/Communuity';
import Areas from 'pages/Areas';
import CES from 'pages/CES';

const Router = () => {
  return (
    <>
      <Nav />
      <Body>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/company/*" element={<Company />} />
          <Route path="/business/*" element={<Business />} />
          <Route path="/field/*" element={<Areas />} />
          <Route path="/community/*" element={<Community />} />
          <Route path="/SEP_MSE_AFS_V01" element={<CES />} />
        </Routes>
      </Body>
      <Footer />
    </>
  );
};

const Body = styled.div`
  width: 100vw;
  min-height: 100vh;
  /* margin-top: 70px; */
  display: flex;
`;

export default Router;
