import { getImage } from '@lib';
import { Box, styled, Typography } from '@mui/material';
import { getMiniCluster } from 'apis/ComponentsAPI';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { languageState } from 'states/atoms/LanguageAtom';

const MiniCluster = () => {
  const lang = useRecoilState(languageState)[0];
  const { data: info } = useQuery(['miniCluster', lang], () => getMiniCluster({ lang }));

  return (
    <Box padding={1}>
      <Box>
        <Title variant="h5">{info?.data.attributes.title}</Title>
      </Box>
      <Box padding={1}>
        {info?.data.attributes.list.map(li => (
          <Message>{li}</Message>
        ))}
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <img src={getImage(info?.data.attributes.image)} style={{ width: '60%', objectFit: 'contain', maxWidth: 1000 }} />
        <Message>{info?.data.attributes.image.data.attributes.caption}</Message>
      </Box>
    </Box>
  );
};

const Title = styled(Typography)`
  color: #f0bc5e;
  font-weight: bold;
`;

const Message = styled(Typography)`
  color: #666;
  font-weight: bold;
`;

export default MiniCluster;
