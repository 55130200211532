import { Route, Routes } from 'react-router-dom';
import Layout from 'components/sub/Layout';
import BannerImage from 'resources/img/main_banner.png';
import BusinessIntro from 'components/sub/businessIntro/BusinessIntro';
import BusinessStructure from 'components/sub/businessIntro/BusinessStructure';
import MiniCluster from 'components/sub/businessIntro/MiniCluster';

const subComponents = [
  { path: '1', element: <BusinessIntro /> },
  { path: '2', element: <BusinessStructure /> },
  { path: '3', element: <MiniCluster /> },
];

const Business = () => {
  return (
    <Layout title="사업소개" subTitle="Business introduction" bannerImg={BannerImage}>
      <Routes>
        {subComponents.map(sub => (
          <Route key={sub.path} {...sub} />
        ))}
      </Routes>
    </Layout>
  );
};

export default Business;
