import { Route, Routes } from 'react-router-dom';
import Layout from 'components/sub/Layout';
import BannerImage from 'resources/img/main_banner.png';
import AboutUs from 'components/sub/company/AboutUs';
import Greeting from 'components/sub/company/Greeting';
import History from 'components/sub/company/History';
import Organization from 'components/sub/company/Organization';
import Location from 'components/sub/company/Location';

const subComponents = [
  { path: '1', element: <AboutUs /> },
  { path: '2', element: <Greeting /> },
  { path: '3', element: <History /> },
  { path: '4', element: <Organization /> },
  { path: '5', element: <Location /> },
];

const Company = () => {
  return (
    <Layout title="회사소개" subTitle="About Us" bannerImg={BannerImage}>
      <Routes>
        {subComponents.map(route => (
          <Route key={route.path} {...route} />
        ))}
      </Routes>
    </Layout>
  );
};

export default Company;
