import { API_URL } from '@const';
import { TImageResponse } from 'types';

export const getImage = (img?: TImageResponse) => {
  if (img) {
    return `${API_URL}${img.data.attributes.url}`;
  }

  return '';
};
