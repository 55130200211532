import { Route, Routes } from 'react-router-dom';
import Layout from 'components/sub/Layout';
import BannerImage from 'resources/img/main_banner.png';
import Contact from 'components/sub/community/Contact';
import News from 'components/sub/community/News';

const subPage = [
  {
    path: '1',
    element: <Contact />,
  },
  {
    path: '2',
    element: <News />,
  },
];

const Community = () => {
  return (
    <Layout bannerImg={BannerImage}>
      <Routes>
        {subPage.map(sub => (
          <Route key={sub.path} {...sub} />
        ))}
      </Routes>
    </Layout>
  );
};

export default Community;
