import { getNews } from 'apis/ComponentsAPI';
import NewsBox from 'components/Newsbox';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { languageState } from 'states/atoms/LanguageAtom';

const News = () => {
  const lang = useRecoilState(languageState)[0];
  const { data: info } = useQuery(['news', lang], () => getNews({ lang }));

  useEffect(() => {
    info?.data?.forEach(data => console.log(data));
  }, [info]);

  return <NewsBox data={info}></NewsBox>;
};

export default News;
