import { styled, Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import logo from 'resources/img/footer_logo.png';
import { languageState } from 'states/atoms/LanguageAtom';

const Footer = () => {
  const lang = useRecoilState(languageState)[0];
  const info = useMemo(
    () =>
      (lang ?? 'ko') === 'ko'
        ? {
            tel: '031-8041-1784',
            fax: '031-8041-1786',
            address1: '본사: 경기도 시흥시 산기대학로 237, 435호(정왕동, 한국공학대학교, 경기산학융합관)',
            address2: '연구소: 경기도 시흥시 산기대학로 237, 419호(정왕동, 한국공학대, 기술혁신파크)',
            copy: 'Copyright ⓒ 에스이피협동조합 All rights reserved',
          }
        : {
            tel: '+82 31-8041-1784',
            fax: '+82 31-8041-1786',
            address1: 'Head office: Room 435, Industry-Univercity Convergence Building, 237 Sangidaehak-ro, Siheung-si, Gyenonggi-do(TECH Univercity of Korea, Jeongwang-dong)',
            address2: 'Research center: Room 419, TIP Building, 237 Sangidaehak-ro, Siheung-si, Gyenonggi-do(TECH Univercity of Korea, Jeongwang-dong)',
            copy: 'Copyright ⓒ SEPCooperatives All rights reserved',
          },
    [lang],
  );
  return (
    <Container>
      <Box flexDirection="row" display="flex" alignItems="center">
        <Box>
          <img src={logo} />
        </Box>
        <Box paddingLeft={3}>
          <InfoText>TEL: {info.tel}</InfoText>
          <InfoText>FAX: {info.fax}</InfoText>
          <InfoText>{info.address1}</InfoText>
          <InfoText>{info.address2}</InfoText>
        </Box>
      </Box>
      <Box>
        <Copyright>{info.copy}</Copyright>
      </Box>
    </Container>
  );
};

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 80px;
  bottom: 0px;
  padding: 10px;
  background-color: #666;

  justify-content: center;
  align-items: center;
`;

const InfoText = styled(Typography)`
  color: #eee;
  font-size: 13px;
`;

const Copyright = styled(Typography)`
  color: #aaa;
  font-size: 12px;
`;

export default Footer;
