import { useState } from 'react';
import { GOOGLE_API_KEY, POSITION } from '@const';
import { Box, styled, Typography } from '@mui/material';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { getLocation } from 'apis/ComponentsAPI';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { languageState } from 'states/atoms/LanguageAtom';
import RoomIcon from '@mui/icons-material/Room';
const Location = () => {
  const lang = useRecoilState(languageState)[0];
  const { data: info } = useQuery(['location', lang], () => getLocation({ lang }));

  const { isLoaded } = useLoadScript({ googleMapsApiKey: GOOGLE_API_KEY });
  const [mapLoaded, setMapLoaded] = useState(false);
  const position = useState(POSITION)[0];

  const onClickKakaoNavi = () => {
    if (info) {
      window.open(info.data.attributes.kakao);
    }
  };

  const onClickGoogleNavi = () => {
    if (info) {
      window.open(info.data.attributes.google);
    }
  };

  return (
    <>
      <Box padding={2}>
        <MapBox>
          {isLoaded && (
            <GoogleMap onLoad={() => setMapLoaded(true)} id="map" zoom={15} center={POSITION} mapContainerStyle={{ width: '90%', height: '500px' }}>
              {mapLoaded && position && <Marker zIndex={100} position={position} visible title="한국공학대학" />}
            </GoogleMap>
          )}
          <NaviBox paddingX={5} paddingY={2}>
            <NaviItem paddingX={1} onClick={onClickKakaoNavi}>
              <RoomIcon />
              <Typography>Kakao Map</Typography>
            </NaviItem>
            <NaviItem paddingX={1} onClick={onClickGoogleNavi}>
              <RoomIcon />
              <Typography>Google Map</Typography>
            </NaviItem>
          </NaviBox>
        </MapBox>
        <InfoBox padding={1} marginLeft={5} marginRight={5}>
          <InfoTitle variant="h6" paddingRight={2}>
            Address
          </InfoTitle>
          <InfoSub>{info?.data.attributes.address}</InfoSub>
        </InfoBox>
        <InfoBox marginLeft={5} marginRight={5}>
          <InfoTitle variant="h6" paddingRight={2}>
            Tel
          </InfoTitle>
          <InfoSub>{info?.data.attributes.tel}</InfoSub>
        </InfoBox>
      </Box>
    </>
  );
};

const MapBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NaviBox = styled(Box)`
  display: flex;
  align-self: flex-start;
`;

const NaviItem = styled(Box)`
  display: flex;
  cursor: pointer;
`;

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  background-color: #eaa932;
`;

const InfoTitle = styled(Typography)`
  font-weight: bold;
  flex: 1;
  text-align: right;

  color: #ffe6b7;
`;

const InfoSub = styled(Typography)`
  flex: 6;
  color: #feebc6;
  font-size: 14px;
`;

export default Location;
