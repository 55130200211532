import { ENDPOINT } from '@const';
import axios from 'axios';
import { IRequest, TMenuResponse } from 'types';

export const getMenus = async ({ lang }: IRequest): Promise<TMenuResponse> => {
  const result = await axios.request({
    method: 'GET',
    url: ENDPOINT.menu.getMenu,
    params: {
      populate: 'menus',
      locale: lang ?? 'ko',
    },
  });
  const data = result.data as TMenuResponse;

  return data;
};
