import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import styled, { css } from 'styled-components';
import LanguageSelect from './LanguageSelect';
import { LANG_LIST } from '@const';
import { getMenus } from 'apis/MenuAPI';
import { useRecoilState } from 'recoil';
import { languageState } from 'states/atoms/LanguageAtom';
import { MenuState } from 'states/atoms/MenuAtom';
import { IActiveable, IStrapiObj, ISubMenu, TLangCode } from 'types';
import { useLocation, useNavigate } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
const Nav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [lang, setLang] = useRecoilState(languageState);
  const setMenus = useRecoilState(MenuState)[1];
  const { data: pages, refetch } = useQuery(['menu', { lang: lang }], () => getMenus({ lang: lang }));
  const [menuAnime, menuAnimeApi] = useSpring(() => ({
    opacity: 0,
    marginTop: -10,
    display: 'none',
  }));
  const [subAnime, subAnimeApi] = useSpring(() => ({
    opacity: 0,
    top: -240,
  }));

  // 메인 메뉴 색상 Active
  const [background, setBackground] = useState(false);
  const [subActive, setSubActive] = useState(false);

  const handleScroll = () => {
    if (window.scrollY < 10) {
      setBackground(false);
    } else {
      setBackground(true);
    }
  };

  const onLangChange = (code: TLangCode) => {
    setLang(code);
    refetch();
  };

  const moveTo = (url: string) => {
    navigate(url);
  };

  const renderSubMenu = (subMenu?: IStrapiObj<ISubMenu>[]) => {
    if (subMenu) {
      const sorted = [...subMenu].sort((a, b) => a.attributes.order - b.attributes.order);

      return sorted?.map(sub => (
        <SubMenuText dangerouslySetInnerHTML={{ __html: sub.attributes.name }} onClick={() => moveTo(sub.attributes.link)} key={sub.id}>
          {/* {`${sub.attributes.name}`} */}
        </SubMenuText>
      ));
    }

    return <></>;
  };

  const renderMenus = useMemo(
    () =>
      pages?.data?.map(page => (
        <MenuItem key={page.id} onMouseEnter={() => setSubActive(true)} onMouseLeave={() => setSubActive(false)}>
          <MenuText onClick={() => moveTo(page.attributes.link)}>{page.attributes.name}</MenuText>
          <AnimatedSubMenuWrapper style={menuAnime} active={subActive}>
            {renderSubMenu(page.attributes.menus?.data)}
          </AnimatedSubMenuWrapper>
        </MenuItem>
      )),
    [pages, subActive],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (pages) {
      setMenus(pages);
    }
  }, [pages]);

  useEffect(() => {
    if (subActive) {
      subAnimeApi.start({
        opacity: 1,
        top: 70,
      });
      menuAnimeApi.start({
        opacity: 1,
        marginTop: 0,
        display: 'flex',
      });
    } else {
      subAnimeApi.start({
        opacity: 0,
        top: -240,
      });
      menuAnimeApi.start({
        opacity: 0,
        marginTop: -50,
        display: 'none',
      });
    }
  }, [subActive]);

  return (
    <>
      <Container background={subActive || background}>
        <EmptyColumn />

        <LogoWrapper>
          <LanguageSelect onSelected={onLangChange} defaultValue={LANG_LIST[0].value} items={LANG_LIST} containerStyle={styles.selectContainer} style={styles.selectItem} />
          <LogoText onClick={() => moveTo('/')}>SEP</LogoText>
        </LogoWrapper>
        <MenuWrapper>{renderMenus}</MenuWrapper>
        <EmptyColumn />
      </Container>
      <AnimatedSubContaioner style={subAnime} onMouseEnter={() => setSubActive(true)} onMouseLeave={() => setSubActive(false)} />
    </>
  );
};

const styles: { [index: string]: CSSProperties } = {
  selectContainer: {
    marginRight: 20,
  },
  selectItem: {
    color: '#eee',
  },
};

const Container = styled.div<IActiveable>`
  width: 100vw;
  height: 70px;
  top: 0px;
  position: fixed;
  display: flex;
  z-index: 999;
  transition: all 0.3s ease-in;

  ${({ background }) =>
    background &&
    css`
      background-color: rgba(0, 0, 0, 0.6);
    `}
`;

const SubContainer = styled.div<IActiveable>`
  width: 100vw;
  height: 210px;
  position: fixed;
  display: flex;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.6);
`;

const AnimatedSubContaioner = animated(SubContainer);

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: #fff;
`;

const LogoText = styled.p`
  font-size: 30px;
  font-weight: bold;

  cursor: pointer;

  @media (max-width: 1024px) {
    font-size: 20px;
  }

  @media (max-width: 640px) {
    display: none;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  flex: 3;
`;

const SubMenuWrapper = styled.div<IActiveable>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AnimatedSubMenuWrapper = animated(SubMenuWrapper);

const MenuItem = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  color: #fff;
  cursor: pointer;

  align-items: center;
  padding-top: 10px;
`;

const MenuText = styled.p`
  text-align: center;
  white-space: pre-line;
`;
const SubMenuText = styled.p`
  font-size: 13px;
  padding: 5px;
  margin: 0px;
  color: #ddd;
  text-align: center;
`;

const EmptyColumn = styled.div`
  flex: 0.5;

  @media (max-width: 1024px) {
    flex: 0.1;
  }
`;

export default Nav;
