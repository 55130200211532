import { getImage } from '@lib';
import { Box } from '@mui/material';
import { getHistory } from 'apis/ComponentsAPI';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { languageState } from 'states/atoms/LanguageAtom';

const History = () => {
  const lang = useRecoilState(languageState)[0];
  const { data: info } = useQuery(['history', lang], () => getHistory({ lang }));

  return (
    <>
      <Box sx={{ display: 'flex', minHeight: 600 }}>
        <Box padding={3} sx={{ width: '100%' }}>
          <img src={getImage(info?.data.attributes.image)} style={{ height: '90%' }} />
        </Box>
      </Box>
    </>
  );
};

export default History;
