import styled from '@emotion/styled';
import { Paper, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';
import { animated, useSpring } from 'react-spring';

type Props = {
  onClick?: () => void;
  title?: string;
  subTitle?: string;
  icon?: ReactElement;
};

const MenuCard = ({ title, subTitle, icon, onClick }: Props) => {
  const [isHover, setIsHover] = useState(false);
  const titleStyle = useSpring({
    color: isHover ? '#eba626' : '#333',
  });
  const mainStyle = useSpring({
    scale: isHover ? 1.1 : 1,
    opacity: isHover ? 1 : 0.9,
    backgroundColor: isHover ? '#fff8eb' : '#fff',
  });

  const onCardClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <AnimatedPaper style={mainStyle} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} onClick={onCardClick} elevation={2}>
      {icon && icon}
      <AnimatedTitle style={titleStyle}>{title}</AnimatedTitle>
      <SubTitle>{subTitle}</SubTitle>
    </AnimatedPaper>
  );
};

const MenuPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 220px;
  height: 220px;
  margin: 0.5vw;
  background-color: #fff;
  border-radius: 120px;

  opacity: 0.9;

  @media (max-width: 1024px) {
    width: 180px;
    height: 180px;
  }
`;

const Title = styled(Typography)`
  font-size: 28px;
  font-weight: bold;
  color: #333;

  @media (max-width: 1024px) {
    font-size: 22px;
  }
`;

const AnimatedTitle = animated(Title);
const AnimatedPaper = animated(MenuPaper);

const SubTitle = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
  color: #666;

  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;

export default MenuCard;
