import { FormControl, NativeSelect } from '@mui/material';
import { ChangeEvent, CSSProperties } from 'react';
import { TLangCode, TLangSelectItem } from 'types';

type Props = {
  containerStyle?: CSSProperties;
  style?: CSSProperties;
  defaultValue?: string;
  items: TLangSelectItem[];
  onSelected?(code: TLangCode): void;
};

const LanguageSelect = ({ containerStyle, style, defaultValue, items, onSelected }: Props) => {
  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as TLangCode;

    if (typeof onSelected === 'function') {
      onSelected(value);
    }
  };

  return (
    <FormControl style={containerStyle}>
      <NativeSelect
        defaultValue={defaultValue}
        inputProps={{
          name: 'lang',
        }}
        onChange={onChange}
        style={style}
      >
        {items.map(item => (
          <option style={{ color: '#333' }} key={item.value} value={item.value}>
            {item.name}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

export default LanguageSelect;
