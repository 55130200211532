import { IPosition, TLangSelectItem, TMenuResponse } from 'types';

export const API_URL = 'https://landing-strapi.sepai.click';

export const ENDPOINT = {
  menu: {
    getMenu: `${API_URL}/api/pages`,
  },
  components: {
    main: `${API_URL}/api/main`,
    aboutUs: `${API_URL}/api/about-us`,
    greeting: `${API_URL}/api/greeting`,
    history: `${API_URL}/api/history`,
    organization: `${API_URL}/api/organization`,
    location: `${API_URL}/api/location`,
    businessIntroduction: `${API_URL}/api/business-introduction`,
    businessStructure: `${API_URL}/api/business-structure`,
    miniCluster: `${API_URL}/api/mini-cluster`,
    kMetaverse: `${API_URL}/api/k-metaverse`,
    kIndustrial: `${API_URL}/api/k-indust`,
    nZaas: `${API_URL}/api/nzaas`,
    enms: `${API_URL}/api/enm`,
    netZero: `${API_URL}/api/net-zero`,
    contact: `${API_URL}/api/contacts`,
    news: `${API_URL}/api/news`,
    smartLed: `${API_URL}/api/smart-led`,
  },
};

export const LANG_LIST: TLangSelectItem[] = [
  {
    name: 'Korean',
    value: 'ko',
  },
  {
    name: 'English',
    value: 'en',
  },
];

export const PAGES: TMenuResponse = {
  data: undefined,
  meta: undefined,
};

export const GOOGLE_API_KEY = 'AIzaSyCR-v0M4b4WU_X-nZWAgfJjRrNx07IXIb0';

export const POSITION: IPosition = {
  lat: 37.3401906,
  lng: 126.7335293,
};
