import { getImage } from '@lib';
import { Box } from '@mui/material';
import { getNZaas } from 'apis/ComponentsAPI';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { languageState } from 'states/atoms/LanguageAtom';

const NZaas = () => {
  const lang = useRecoilState(languageState)[0];
  const { data: info } = useQuery(['nzaas', lang], () => getNZaas({ lang }));

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <img src={getImage(info?.data.attributes.image)} style={{ objectFit: 'contain', height: '100%' }} />
    </Box>
  );
};

export default NZaas;
