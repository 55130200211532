import { ChangeEvent, useState } from 'react';
import { Box, styled, TextField, Button, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { languageState } from 'states/atoms/LanguageAtom';
import { createContact } from 'apis/ComponentsAPI';
const Contact = () => {
  const lang = useRecoilState(languageState)[0];
  const [state, setState] = useState({
    title: {
      value: '',
      length: 0,
      maxLength: 100,
      errorMessage: '',
    },
    email: {
      value: '',
      length: 0,
      maxLength: 50,
      errorMessage: '',
    },
    contents: {
      value: '',
      length: 0,
      maxLength: 400,
      errorMessage: '',
    },
  });

  const [uploads, setUploads] = useState<File[]>([]);

  const createMessage = (kor: string, eng: string) => {
    return (lang ?? 'ko') === 'ko' ? kor : eng;
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    let change: any;

    if (name === 'title') {
      change = { ...state.title };
    }
    if (name === 'email') {
      change = { ...state.email };
    }
    if (name === 'contents') {
      change = { ...state.contents };
    }

    if (value.length >= change.maxLength) {
      return;
    }

    change = {
      ...change,
      value: value,
      length: value.length,
    };

    setState(prev => ({ ...prev, [name]: { ...change } }));
  };

  const validation = () => {
    let changedState = { ...state };
    let validate = true;

    if (state.title.value === '') {
      changedState = { ...changedState, title: { ...changedState.title, errorMessage: createMessage('제목을 입력해주세요.', 'Please enter the subject.') } };
      validate = false;
    } else {
      changedState = { ...changedState, title: { ...changedState.title, errorMessage: '' } };
    }

    if (state.email.value === '') {
      changedState = { ...changedState, email: { ...changedState.email, errorMessage: createMessage('이메일을 입력해주세요.', 'Please enter your email address.') } };
      validate = false;
    } else {
      changedState = { ...changedState, email: { ...changedState.email, errorMessage: '' } };
    }

    const emailRegExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    if (!emailRegExp.test(state.email.value)) {
      changedState = { ...changedState, email: { ...changedState.email, errorMessage: createMessage('잘못된 이메일 주소 입니다.', 'Invalid email address.') } };
      validate = false;
    }

    if (state.contents.value === '') {
      changedState = { ...changedState, contents: { ...changedState.contents, errorMessage: createMessage('내용을 입력해주세요.', 'Please enter your details.') } };
      validate = false;
    } else {
      changedState = { ...changedState, contents: { ...changedState.contents, errorMessage: '' } };
    }

    setState({ ...changedState });
    return validate;
  };

  const reset = () => {
    setState({
      title: {
        value: '',
        length: 0,
        maxLength: 100,
        errorMessage: '',
      },
      email: {
        value: '',
        length: 0,
        maxLength: 50,
        errorMessage: '',
      },
      contents: {
        value: '',
        length: 0,
        maxLength: 400,
        errorMessage: '',
      },
    });
  };

  const send = async () => {
    if (!validation()) {
      return;
    }

    const result = await createContact({ data: { title: state.title.value, email: state.email.value, body: state.contents.value } });

    if (result) {
      reset();
      alert(createMessage('문의가 등록되었습니다.', 'registered.'));
    } else {
      alert(createMessage('에러가 발생했습니다. 다음에 다시 시도해주세요.', 'An error has occurred. Please try again next time.'));
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <FormBox>
        <TextField
          error={state.title.errorMessage !== ''}
          helperText={state.title.errorMessage}
          name="title"
          fullWidth
          label={createMessage('제목', 'Subject')}
          value={state.title.value}
          onChange={onChange}
          variant="standard"
        />
        <TextField
          error={state.email.errorMessage !== ''}
          helperText={state.email.errorMessage}
          name="email"
          fullWidth
          label={createMessage('이메일', 'Email')}
          value={state.email.value}
          onChange={onChange}
          variant="standard"
          inputMode="email"
        />
        <TextField
          error={state.contents.errorMessage !== ''}
          helperText={state.contents.errorMessage}
          name="contents"
          multiline
          rows={6}
          maxRows={8}
          fullWidth
          onChange={onChange}
          value={state.contents.value}
          label={createMessage('내용', 'Details')}
          variant="outlined"
        />

        <Button onClick={send} fullWidth variant="contained">
          <Typography>Send</Typography>
        </Button>
      </FormBox>
    </Box>
  );
};

const FormBox = styled(Box)`
  width: 90%;
  height: 500px;
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;
`;

export default Contact;
