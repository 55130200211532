import { ENDPOINT } from '@const';
import axios from 'axios';
import {
  IRequest,
  TMain,
  TAboutUsResponse,
  TBsIntroduction,
  TBsStructure,
  TGreeting,
  THistory,
  TLocation,
  TOrganization,
  TMiniCluster,
  TMetaverse,
  TKIndustrial,
  TNZaas,
  TNetZero,
  TSmartLED,
  TNews,
} from 'types';

export const getMain = async ({ lang }: IRequest): Promise<TMain> => {
  const result = await axios.request({
    method: 'GET',
    url: ENDPOINT.components.main,
    params: {
      locale: lang ?? 'ko',
      populate: 'greetingImage',
    },
  });

  return result.data;
};

export const getAboutUS = async ({ lang }: IRequest): Promise<TAboutUsResponse> => {
  const result = await axios.request({
    method: 'GET',
    url: ENDPOINT.components.aboutUs,
    params: {
      locale: lang ?? 'ko',
      populate: 'image',
    },
  });

  return result.data;
};

export const getGreeting = async ({ lang }: IRequest): Promise<TGreeting> => {
  const result = await axios.request({
    method: 'GET',
    url: ENDPOINT.components.greeting,
    params: {
      locale: lang ?? 'ko',
      populate: 'image',
    },
  });

  return result.data;
};

export const getHistory = async ({ lang }: IRequest): Promise<THistory> => {
  const result = await axios.request({
    method: 'GET',
    url: ENDPOINT.components.history,
    params: {
      locale: lang ?? 'ko',
      populate: 'image',
    },
  });

  return result.data;
};

export const getOrganization = async ({ lang }: IRequest): Promise<TOrganization> => {
  const result = await axios.request({
    method: 'GET',
    url: ENDPOINT.components.organization,
    params: {
      locale: lang ?? 'ko',
      populate: 'image',
    },
  });

  return result.data;
};

export const getLocation = async ({ lang }: IRequest): Promise<TLocation> => {
  const result = await axios.request({
    method: 'GET',
    url: ENDPOINT.components.location,
    params: {
      locale: lang ?? 'ko',
    },
  });

  return result.data;
};

export const getBusinessIntro = async ({ lang }: IRequest): Promise<TBsIntroduction> => {
  const result = await axios.request({
    method: 'GET',
    url: ENDPOINT.components.businessIntroduction,
    params: {
      locale: lang ?? 'ko',
      populate: 'image',
    },
  });

  return result.data;
};

export const getBusinessStructure = async ({ lang }: IRequest): Promise<TBsStructure> => {
  const result = await axios.request({
    method: 'GET',
    url: ENDPOINT.components.businessStructure,
    params: {
      locale: lang ?? 'ko',
      populate: 'image',
    },
  });

  return result.data;
};

export const getMiniCluster = async ({ lang }: IRequest): Promise<TMiniCluster> => {
  const result = await axios.request({
    method: 'GET',
    url: ENDPOINT.components.miniCluster,
    params: {
      locale: lang ?? 'ko',
      populate: 'image',
    },
  });

  return result.data;
};

export const getMetaverse = async ({ lang }: IRequest): Promise<TMetaverse> => {
  const result = await axios.request({
    method: 'GET',
    url: ENDPOINT.components.kMetaverse,
    params: {
      locale: lang ?? 'ko',
      populate: 'image',
    },
  });

  return result.data;
};

export const getIndustrial = async ({ lang }: IRequest): Promise<TKIndustrial> => {
  const result = await axios.request({
    method: 'GET',
    url: ENDPOINT.components.kIndustrial,
    params: {
      locale: lang ?? 'ko',
      populate: 'image',
    },
  });

  console.log(lang ?? 'ko');

  return result.data;
};

export const getNZaas = async ({ lang }: IRequest): Promise<TNZaas> => {
  const result = await axios.request({
    method: 'GET',
    url: ENDPOINT.components.nZaas,
    params: {
      locale: lang ?? 'ko',
      populate: 'image',
    },
  });

  return result.data;
};

export const getEnMS = async ({ lang }: IRequest): Promise<TNZaas> => {
  const result = await axios.request({
    method: 'GET',
    url: ENDPOINT.components.enms,
    params: {
      locale: lang ?? 'ko',
      populate: 'image',
    },
  });

  return result.data;
};

export const getNetZero = async ({ lang }: IRequest): Promise<TNetZero> => {
  const result = await axios.request({
    method: 'GET',
    url: ENDPOINT.components.netZero,
    params: {
      locale: lang ?? 'ko',
      populate: 'image',
    },
  });

  return result.data;
};

export const getSmartLED = async ({ lang }: IRequest): Promise<TSmartLED> => {
  const result = await axios.request({
    method: 'GET',
    url: ENDPOINT.components.smartLed,
    params: {
      locale: lang ?? 'ko',
      populate: 'image',
    },
  });

  return result.data;
};
export const getNews = async ({ lang }: IRequest): Promise<TNews> => {
  const result = await axios.request({
    method: 'GET',
    url: ENDPOINT.components.news,
    params: {
      locale: lang ?? 'ko',
      populate: 'image',
    },
  });

  return result.data;
};

export interface ICreateContact {
  data: {
    title: string;
    email: string;
    body: string;
    status?: string;
  };
}

export const createContact = async (data: ICreateContact): Promise<boolean> => {
  const result = await axios.request({ method: 'POST', url: ENDPOINT.components.contact, data: { ...data, status: 'Waiting' } });
  return result.status === 200;
};
