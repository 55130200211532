import { getImage } from '@lib';
import { Box, styled, Typography } from '@mui/material';
import { getGreeting } from 'apis/ComponentsAPI';
import { useQuery } from 'react-query';
import useMeasure from 'react-use-measure';
import { useRecoilState } from 'recoil';
import { languageState } from 'states/atoms/LanguageAtom';

const Greeting = () => {
  const lang = useRecoilState(languageState)[0];
  const { data: info } = useQuery(['greeting', lang], () => getGreeting({ lang }));
  const [ref, bounds] = useMeasure();
  const [ref2, bounds2] = useMeasure();

  return (
    <>
      <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, minHeight: 400 }} padding={1} justifyContent="center" alignItems="center" flexDirection="row">
        <Box>
          <img ref={ref} src={getImage(info?.data.attributes.image)} style={{ objectFit: 'cover', width: '100%', maxWidth: 300, zIndex: 3 }} />
          <div style={{ width: '90%', maxWidth: 250, height: bounds.height + 50, marginTop: -bounds.height - 53, backgroundColor: '#f0bc5e', zIndex: 1 }} />
          <Title dangerouslySetInnerHTML={{ __html: info?.data.attributes.title ?? '' }}></Title>
          <SubTitle>{info?.data.attributes.subTitle}</SubTitle>
        </Box>
        <Box flex={2}>
          <Message>{info?.data.attributes.message}</Message>
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' }, minHeight: 400, marginTop: 5 }} padding={1} justifyContent="center" alignItems="center" flexDirection="column">
        <Box flex={1} padding={3}>
          <img ref={ref2} src={getImage(info?.data.attributes.image)} style={{ objectFit: 'cover', width: '100%', maxWidth: 300, zIndex: 3 }} />
          <div style={{ width: '90%', maxWidth: 250, height: bounds2.height + 50, marginTop: -bounds2.height - 53, backgroundColor: '#f0bc5e', zIndex: 1 }} />
          <Title>{info?.data.attributes.title}</Title>
          <SubTitle>{info?.data.attributes.subTitle}</SubTitle>
        </Box>
        <Box flex={2}>
          <Message>{info?.data.attributes.message}</Message>
        </Box>
      </Box>
    </>
  );
};

const Title = styled(Typography)`
  font-family: 'Song Myung', serif;
  letter-spacing: 5px;
  margin-top: 10px;
  font-size: 22px;
  font-weight: bold;
`;
const SubTitle = styled(Typography)`
  font-family: 'Song Myung', serif;
  letter-spacing: 3px;
  margin-top: 1px;
  font-size: 19;
  font-weight: bold;
`;

const Message = styled(Typography)`
  white-space: pre-line;
  color: #666;
`;

export default Greeting;
