import { Box, styled } from '@mui/material';
import { TImageResponse, TNews } from 'types';
import { getImage } from '@lib';
import PaginationRounded from './PageButton';
import { Link } from 'react-router-dom';

interface INewsBoxProps {
  data?: TNews;
}
const Containor = styled(Box)`
  height: 160px;
  margin: 6px 8px;
  display: flex;
  border-radius: 7px;
  border-top: 1px groove;
  box-shadow: 1px 5px 3px 0px rgba(0, 0, 0, 0.3);
  :hover {
    background-color: rgba(0, 0, 0, 0.2);
    color: blue;
  }
`;

const ImgBox = styled(Box)`
  margin: 15px 20px;
  width: 130px;
  height: 130px;
`;
const StringComp = styled(Box)`
  margin-left: 15px;
  display: flex;
  flex-direction: column;
`;
const TitleBox = styled(Box)`
  width: 440px;
  margin: 15px 0px;
  font-weight: 600;
`;
const ParBox = styled(Box)`
  width: 420px;
  height: 100px;
  font-size: 11px;
`;

const Onclick = (link: string) => {
  window.location.href = link;
};

function NewsBox({ data }: INewsBoxProps) {
  const renderImage = (strapiImage: TImageResponse) => {
    const imageUrl = getImage(strapiImage);
    const { name, caption } = strapiImage.data.attributes;
    return <img alt={caption} title={name} src={imageUrl} style={{ width: '100%', height: '100% ' }} />;
  };

  return (
    <>
      <Box display="flex" flexWrap="wrap" justifyContent="space-evenly">
        {data?.data?.map(news => (
          <Containor onClick={() => Onclick(news.attributes.URL)}>
            <ImgBox>{renderImage(news.attributes.image)}</ImgBox>
            <StringComp>
              <TitleBox>{news.attributes.title}</TitleBox>
              <ParBox>{news.attributes.pargraph}</ParBox>
            </StringComp>
          </Containor>
        ))}
      </Box>

      <PaginationRounded />
    </>
  );
}
export default NewsBox;
