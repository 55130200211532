import { getImage } from '@lib';
import { Box, Typography, styled } from '@mui/material';
import { getAboutUS } from 'apis/ComponentsAPI';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { languageState } from 'states/atoms/LanguageAtom';

const AboutUs = () => {
  const lang = useRecoilState(languageState)[0];
  const { data: info } = useQuery(['aboutUs', lang], () => getAboutUS({ lang }));

  const renderImage = useMemo(() => {
    if (info) {
      const { image } = info.data.attributes;
      return <img src={getImage(image)} style={{ width: 300 }} />;
    }

    return <></>;
  }, [info]);

  return (
    <>
      <Box sx={{ display: { xs: 'flex' }, minHeight: 400 }} justifyContent="center" alignItems="center" flexDirection="column">
        <Box width={300}>{renderImage}</Box>
        <Box padding={3}>
          <MessageTypo>{info?.data.attributes.message}</MessageTypo>
        </Box>
      </Box>
    </>
  );
};

const MessageTypo = styled(Typography)`
  white-space: pre-wrap;
  color: #666;
`;

export default AboutUs;
