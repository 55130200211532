import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';

export default function PaginationRounded() {
  return (
    <Box display="flex" justifyContent="center" sx={{ pt: 1 }}>
      <Pagination showFirstButton showLastButton count={1} size="small" shape="circular" />
    </Box>
  );
}
