import { getImage } from '@lib';
import { Box, styled, Typography } from '@mui/material';
import { getBusinessStructure } from 'apis/ComponentsAPI';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { languageState } from 'states/atoms/LanguageAtom';

const BusinessStructure = () => {
  const lang = useRecoilState(languageState)[0];
  const { data: info } = useQuery(['bsStructure', lang], () => getBusinessStructure({ lang }));

  return (
    <Box padding={1}>
      <Box>
        <Title variant="h5">{info?.data.attributes.title}</Title>
      </Box>
      <Box>
        <Message padding={1}>{info?.data.attributes.message}</Message>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" padding={1}>
        <img src={getImage(info?.data.attributes.image)} style={{ width: '70%', objectFit: 'contain', maxWidth: 1000 }} />
      </Box>
    </Box>
  );
};

const Title = styled(Typography)`
  color: #f0bc5e;
  font-weight: bold;
`;

const Message = styled(Typography)`
  color: #666;
  font-weight: bold;
`;

export default BusinessStructure;
